<template>
  <g>
    <svg:style>
      .cls-44,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        fill: url(#glass-pattern);
      }
    </svg:style>
    <g id="B03">
      <polyline
        id="glass"
        class="cls-44"
        :points="`${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 85.44} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 85.44} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 59.99} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 59.99} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 85.44}`"/>
      <polyline
        id="glass-2"
        data-name="glass"
        class="cls-5"
        :points="`${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 124.28} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 124.28} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 98.83} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 98.83} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 124.28}`"/>
      <polyline
        id="glass-3"
        data-name="glass"
        class="cls-6"
        :points="`${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 201.97} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 201.97} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 176.52} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 176.52} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 201.97}`"/>
      <polyline
        id="glass-4"
        data-name="glass"
        class="cls-7"
        :points="`${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 163.13} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 163.13} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 137.68} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 137.68} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 163.13}`"/>
      <polyline
        id="glass-5"
        data-name="glass"
        class="cls-8"
        :points="`${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 240.82} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 240.82} ${doorLeftWidth1 + 83.54} ${doorTopHeight1 + 215.37} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 215.37} ${doorLeftWidth1 + 58.09} ${doorTopHeight1 + 240.82}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  },
}
</script>
